<template>
  <div class="catalogue-buttons-container">
    <button
      @click="$emit('buttonClicked')"
      v-bind:class="[
        'catalogue-buttons btn btn-outline btn-outline-primary btn-active-light-primary me-2 mb-2',
        { disabled, active: selected },
      ]"
    >
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  name: "CatalogueButtons",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogue-buttons {
  width: 120px;
  border-radius: 20px;
  padding: 0.4rem 1.5rem !important;
  margin-right: 2rem !important;
}
</style>
