<template>
  <div class="catalogue-range-modal">
    <div
      class="modal fade"
      :id="'catalogue_range_modal_' + type"
      tabindex="-1"
      aria-hidden="true"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header"></div>
          <!--end::Modal header-->
          <div class="modal-body py-2 px-lg-17">
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="text-capitalize">{{ type }}</span>
              </label>
              <!--end::Label-->
              <el-slider
                :marks="marks"
                :step="1000"
                :max="100000"
                v-model="rangeSelected"
                @change="$emit('change', $event)"
                range
                show-stops
              >
              </el-slider>
            </div>
          </div>
          <!--begin::Form-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              class="btn btn-primary me-3"
              data-bs-toggle="modal"
              :data-bs-target="'#catalogue_range_modal_' + type"
            >
              Apply
            </button>
            <button
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              :data-bs-target="'#catalogue_range_modal_' + type"
              @click="clearSelectedRange"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      ref="modalOpenButtonRef"
      type="button"
      class="d-none btn btn-sm btn-primary me-3"
      data-bs-toggle="modal"
      :data-bs-target="'#catalogue_range_modal_' + type"
    ></a>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CatalogueRangeModal",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const modalOpenButtonRef = ref(null);
    const rangeSelected = ref([0, 0]);
    const marks = ref({
      0: "0",
      10000: "10K",
      20000: "20K",
      30000: "30K",
      40000: "40K",
      50000: "50K",
      60000: "60K",
      70000: "70K",
      80000: "80K",
      90000: "90K",
      100000: "100K",
    });

    const showModal = () => {
      modalOpenButtonRef.value.click();
    };

    const clearSelectedRange = () => {
      rangeSelected.value = [0, 0];
      context.emit("clearCatalogueRange");
    };

    return {
      modalOpenButtonRef,
      rangeSelected,
      marks,
      //Methods
      showModal,
      clearSelectedRange,
    };
  },
};
</script>

<style scoped></style>
