<template>
  <div class="catalogue-data-picker-modal">
    <div
      class="modal fade"
      :id="'catalogue_data_picker_modal_' + type"
      tabindex="-1"
      aria-hidden="true"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header"></div>
          <!--end::Modal header-->
          <div class="modal-body py-2 px-lg-17">
            <div class="d-flex flex-column mb-8 fv-row">
              <!--begin::Label-->
              <label class="d-flex align-items-center fs-6 fw-bold mb-2">
                <span class="text-capitalize">{{ type }}</span>
              </label>
              <!--end::Label-->
              <el-date-picker
                v-model="dateSelected"
                value-format="YYYY-MM-DD"
                @change="$emit('change', $event)"
                class="w-100"
                type="month"
                placeholder="Pick a month"
              >
              </el-date-picker>
            </div>
          </div>
          <!--begin::Form-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              class="btn btn-primary me-3"
              data-bs-toggle="modal"
              :data-bs-target="'#catalogue_data_picker_modal_' + type"
            >
              Apply
            </button>
            <button
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              :data-bs-target="'#catalogue_data_picker_modal_' + type"
              @click="clearSelectedData"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      ref="modalOpenButtonRef"
      type="button"
      class="d-none btn btn-sm btn-primary me-3"
      data-bs-toggle="modal"
      :data-bs-target="'#catalogue_data_picker_modal_' + type"
    ></a>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CatalogueDatePickerModal",
  props: {
    type: {
      type: String,
      required: true,
    },
    // dataPickerOptions: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       disabledDate(time) {
    //         console.log(time);
    //         return time.getTime() > Date.now();
    //       },
    //     };
    //   },
    // },
  },
  setup(props, context) {
    const modalOpenButtonRef = ref(null);
    const dateSelected = ref(null);

    const showModal = () => {
      modalOpenButtonRef.value.click();
    };

    const clearSelectedData = () => {
      dateSelected.value = null;
      context.emit("change", null);
    };

    return {
      modalOpenButtonRef,
      dateSelected,
      //Methods
      showModal,
      clearSelectedData,
    };
  },
};
</script>

<style scoped></style>
