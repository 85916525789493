<template>
  <div class="genres-search-modal">
    <div
      class="modal fade"
      id="genres_search_modal"
      tabindex="-1"
      aria-hidden="true"
      role="dialog"
      aria-labelledby="createForecastModal"
    >
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-800px">
        <!--begin::Modal content-->
        <div class="modal-content px-4 py-2">
          <!--begin::Modal header-->
          <div class="modal-header d-flex align-items-center">
            <div>
              <h2 class="heading">Genres</h2>
            </div>
            <div class="ml-auto d-flex align-items-center">
              <input
                class="form-control form-control-solid me-3"
                placeholder="Search"
                v-model="filterQuery"
                @input="onFilterGenres"
              />
              <div
                class="spinner-border text-primary spinner-border-sm"
                role="status"
                v-if="loading"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <!--end::Modal header-->
          <div class="modal-body">
            <div class="genres">
              <el-row>
                <el-col
                  :span="8"
                  v-for="(genre, index) in filteredGenres"
                  :key="index"
                >
                  <h6
                    :class="[
                      'cursor-pointer genre',
                      genre.status === true
                        ? 'genre-style'
                        : genre.status === false
                        ? 'scrapped-genre'
                        : '',
                    ]"
                    @click="pushGenre(genre)"
                  >
                    {{
                      genre.total_artists
                        ? genre.label + "(" + genre.total_artists + ")"
                        : genre.label
                    }}
                  </h6>
                </el-col>
              </el-row>
            </div>
            <div
              class="selected-genre mt-8 d-flex align-items-center"
              v-if="selectedGenres"
            >
              <h2 class="heading">Selected Genre :</h2>
              <h6 class="ms-10">{{ selectedGenres }}</h6>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <button
              class="btn btn-sm btn-primary me-3"
              data-bs-toggle="modal"
              data-bs-target="#genres_search_modal"
            >
              Add to queue
            </button>
            <button
              type="reset"
              class="btn btn-sm btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#genres_search_modal"
              @click="clearSelectedGenres"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <a
      ref="modalOpenButtonRef"
      type="button"
      class="d-none btn btn-sm btn-primary me-3"
      data-bs-toggle="modal"
      data-bs-target="#genres_search_modal"
    ></a>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "GenresSearchModal",
  props: {
    allGenres: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const modalOpenButtonRef = ref(null);
    const selectedGenres = ref(null);
    const filteredGenres = ref([]);
    const filterQuery = ref("");
    const loading = ref(false);
    const showModal = () => {
      modalOpenButtonRef.value.click();
    };

    const genre = computed(() => {
      return store.getters["AIBetaBench/getFilterDataGenre"];
    });

    const onFilterGenres = () => {
      loading.value = true;
      filteredGenres.value = props.allGenres.filter((genre) =>
        genre.label.includes(filterQuery.value)
      );
      loading.value = false;
    };

    watch(
      () => genre.value,
      (value) => {
        if (selectedGenres.value !== value) {
          selectedGenres.value = value;
        }
      }
    );

    watch(
      () => props.allGenres,
      (value) => {
        if (value.length > 0) {
          filteredGenres.value = value;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const pushGenre = (payload) => {
      selectedGenres.value = payload.label;
      store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", selectedGenres.value);
    };

    // watch(
    //   () => selectedGenres.value,
    //   (value) => {
    //     store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", value);
    //   }
    // );

    const clearSelectedGenres = () => {
      selectedGenres.value = null;
      store.commit("AIBetaBench/SET_FILTER_DATA_GENRE", null);
    };

    return {
      modalOpenButtonRef,
      selectedGenres,
      genre,
      filterQuery,
      loading,
      filteredGenres,
      //Methods
      showModal,
      clearSelectedGenres,
      onFilterGenres,
      pushGenre,
    };
  },
};
</script>

<style lang="scss" scoped>
#genres_search_modal {
  .modal-header {
    border-bottom: none !important;
  }
  .modal-footer {
    border-top: none !important;
  }
  .genres {
    max-height: 20rem !important;
    overflow-y: scroll;
  }
  .genre:hover {
    text-decoration: underline;
  }

  .genre-style {
    color: darkgray !important;
  }
  .scrapped-genre {
    color: blue !important ;
  }
}
</style>
